@import url(https://fonts.googleapis.com/css?family=Montserrat|Playfair+Display:400i&display=swap);
#h1 {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#top{
  height:100%;
  top: 50%;
  margin-top: -150px;
  min-height: 50%;
  position: absolute;
}
img{
  display: flex;
  /* min-height: 40vmin; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  /* display: flex; */
  /* min-height: 100vh; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}

#uploader {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width:50%;
  margin-bottom:10px;
}

.sponge {
  visibility: hidden;
  height: 40vmin;
  align-items: center;
  
}

.sponge-visible {
  visibility: visible;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

