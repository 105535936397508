@import url('https://fonts.googleapis.com/css?family=Montserrat|Playfair+Display:400i&display=swap');

#h1 {
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

#top{
  height:100%;
  top: 50%;
  margin-top: -150px;
  min-height: 50%;
  position: absolute;
}
img{
  display: flex;
  /* min-height: 40vmin; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  /* display: flex; */
  /* min-height: 100vh; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}

#uploader {
  -webkit-appearance: none;
  appearance: none;
  width:50%;
  margin-bottom:10px;
}

.sponge {
  visibility: hidden;
  height: 40vmin;
  align-items: center;
  
}

.sponge-visible {
  visibility: visible;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
